.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e7e8ec;
  align-items: center;
  position: relative;
  padding-left: 24px;
}

.search-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 9px;
  background: #fff;
  width: calc(100% - 60px);
}

.search-wrapper .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-icon-button,
.delete-icon-button {
  position: absolute;
  padding: 0;
}

.search-icon-button {
  top: 15px;
}

.delete-icon-button {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-icon-button:hover path,
.delete-icon-button:hover path {
  fill: #0b1641;
}

.content-row {
  width: 100% !important;
  cursor: pointer;
}

.content-row:hover {
  background: #fbfbfc;
}

.header-row {
  border-bottom: 1px solid #e7e8ec !important;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.table-cell {
  border: none;
  padding: 0;
}

.rotate-180 {
  transform: rotate(180deg);
}

.header-button:hover {
  background: none !important;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container + .flex-container {
  margin-top: 24px;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.header-button:hover {
  background: none !important;
}

.input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  font-family: Inter;
  color: #3c4567;
  padding-left: 37px;
}

.input-root::before,
.input-root::after {
  display: none;
}

.input:focus {
  padding-left: 36px;
  border-color: #606783;
  border-width: 2px;
}

.input-root .input-adornment-button:hover {
  background: none;
}

.open-actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.table-cell .open-actions-icon:hover,
.active-actions-icon {
  background: #e7e8ec;
}

.table-cell .open-actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.popover-paper {
  transform: translateX(40px) translateY(12px) !important;
}

.horizontal-direction-top {
  transform: translateX(40px) translateY(-12px) !important;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.action-button-icon {
  margin-right: 16px;
}

.user-link {
  cursor: pointer;
  margin-bottom: 6px;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  margin-right: 25px;
}

.app-icon-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f89f33;
  font-size: 15px;
  text-transform: uppercase;
}

.selected-row {
  background: #f5f6f8;
}

.selected-row:hover {
  background: #f1f1f4;
}

.wrapper {
  height: calc(100vh - 340px);
}

.animate-width,
.animate-width div {
  transition: 0.5s ease all;
}

.column {
  transition: 0.5s ease flex;
  overflow: hidden;
}

.header-row > div {
  transition: 0.5s ease flex;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    height: calc(100vh - 372px);
  }
}
