.panel {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-left: 1px solid #e7e8ec;
  transition: 0.3s ease all;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  opacity: 1;
  transition: 0.3s ease all;
  padding: 32px;
}

.tabs .tab-indicator {
  background: #4c6ad4;
  height: 1px;
}

.tabs {
  padding: 0 12px 0 32px;
  border-bottom: 1px solid #e7e8ec;
}

.tabs-wrapper {
  border-bottom: 1px solid #e7e8ec;
}

.tabs .tab {
  padding: 0;
  min-width: 0;
  width: fit-content;
  color: #858ba0;
  height: 56px;
  text-transform: none;
}

.tab + .tab {
  margin-left: 32px;
}

.tabs .tab-selected {
  color: #4c6ad4;
}

.tabs .view {
  margin-left: auto;
  padding-right: 0;
}

.tabs .view:hover {
  background: none;
}

.panel-hidden {
  opacity: 0;
  pointer-events: none;
}

.panel-top {
  display: flex;
  padding-bottom: 32px;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.app-icon-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f89f33;
  font-size: 30px;
  text-transform: uppercase;
}

.app-icon {
  width: 40px;
  height: 40px;
}

.name-wrapper {
  margin-left: 32px;
  width: calc(100% - 210px);
}

.edit-link {
  display: block;
  margin-left: 24px;
  margin-top: 16px;
}

.edit-button {
  padding: 8px;
}

.edit-button:focus {
  padding: 6px;
}

.edit-button:active {
  padding: 8px;
}

.go-over-wrapper {
  padding: 8px;
  margin-left: auto;
  cursor: pointer;
}

.action-button-icon {
  margin-right: 16px;
}

.popover-button {
  margin-top: 16px;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  transition: none !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e7e8ec;
  margin-top: 8px;
}

.action-button {
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  padding-left: 24px;
}

.info-icon path,
.info-icon circle {
  stroke: #0b1641;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info:not(:empty) {
  width: 469px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  padding: 20px 24px;
  margin-top: 32px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container + .flex-container {
  margin-top: 24px;
}
