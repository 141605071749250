.top-bar {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #e7e8ec;
}

.tooltip {
  background: #0b1641;
}

.tooltip-arrow {
  color: #0b1641;
}

.menu-icon {
  margin-right: 19px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-icon {
  margin-right: 12px;
  max-width: 28px;
  max-height: 28px;
}

.logo-text {
  color: #5C78D8;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
}

/*#541
.search {
  width: 640px;
  margin-left: 19px;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.input {
  padding-left: 48px !important;
} */

.bell-icon {
  margin-left: auto;
}

.menu-icon:hover,
.bell-icon:hover {
  background: #f5f6f8 !important;
  border-radius: 0;
}

.start-icon {
  margin-right: 8px;
}

.exit-button {
  margin-left: auto;
}

@media screen and (max-width: 1023px) {
  .top-bar {
    padding: 0 11px;
  }

  .menu-icon {
    margin-right: 8px;
  }
}