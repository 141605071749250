.wrapper {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 64px);
}

.panel-wrapper {
  height: 100%;
}

.content {
  padding: 32px 0 0 32px;
  width: 800px;
}

.panel-content {
  width: auto;
  padding: 24px 8px 0 32px;
}

.button-back {
  padding: 0;
}

.button-back:hover {
  background: none;
}

.panel-top {
  display: flex;
  padding: 32px 32px 32px 0;
  align-items: center;
}

.name-wrapper {
  margin-left: 32px;
  width: calc(100% - 120px);
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  transition: none !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.action-button {
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  padding-left: 24px;
}

.action-button-icon {
  margin-right: 14px;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.app-icon-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f89f33;
  font-size: 30px;
  text-transform: uppercase;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popover-button {
  margin-top: 16px;
}

.mobile-buttons {
  display: flex;
  padding-bottom: 24px;
}

.panel {
  padding-left: 32px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin-bottom: 32px;
}

.margin-right {
  margin-right: 32px;
}

.panel-title {
  padding: 24px 0px;
  height: 72px;
  display: flex;
  align-items: center;
}

.info-item {
  display: flex;
  min-height: 64px;
  align-items: center;
  max-height: 128px;
}

.info-item + .info-item {
  border-top: 1px solid #e7e8ec;
}

.flex-wrap {
  display: flex;
  flex-flow: nowrap;
  width: calc(100% - 80px);
}

.info-item-title {
  width: 250px;
}

.info-item-value {
  min-width: 105px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  width: 48px;
  height: 48px;
  border: 1px solid #ced0d9;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: #fff;
}

.provider-icon {
  height: 40px;
  min-width: 40px;
  background: #f1f1f4;
  border: 1px solid #e7e8ec;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.provider-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
  transform: translateX(40px) translateY(12px) !important;
}

.provider-popover-button {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 24px;
  text-align: start;
}

.accordion {
  margin-bottom: 32px !important;
  padding: 0;
  box-shadow: none;
}

.accordion::before {
  display: none;
}

.accorion-summary {
  padding: 32px;
}

.accorion-summary:hover {
  background: #fbfbfc;
}

.accorion-summary-content {
  margin: 0 !important;
}

.accorion-summary:active {
  background: #f5f6f8;
}

.accordion-details {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.margin-bottom {
  margin-bottom: 32px;
}

.actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.actions-icon:hover,
.active-actions-icon {
  background: #f5f6f8 !important;
}

.actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.user-icon-wrapper {
  border-radius: 4px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e8ec;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-icon-wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.justify {
  padding: 24px 32px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-button {
  margin-bottom: 24px;
}

.provider-info {
  margin-left: 24px;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .content {
    padding: 24px 16px 16px 16px;
    border: none;
    width: auto;
  }

  .panel-top {
    padding: 27px 32px 0 0;
  }

  .popover-button {
    flex-grow: 1;
  }

  .user-buttons {
    margin-top: 0;
  }

  .flex-wrap {
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 767px) {
  .panel-top {
    padding: 27px 16px 0 0;
  }

  .panel {
    padding: 24px 0 0 16px;
    margin-bottom: 24px;
  }

  .margin-right {
    margin-right: 16px;
  }

  .info-item-title {
    min-width: 150px;
    max-width: 150px;
  }

  .justify {
    padding-right: 16px;
  }

  .accordion {
    margin-bottom: 24px !important;
    padding: 0;
    box-shadow: none;
  }

  .accorion-summary {
    padding: 16px;
  }

  .margin-bottom {
    margin-bottom: 16px;
  }

  .accordion-details {
    padding-left: 14px;
  }

  .provider-info {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
  }

  .ellipsis {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 360px) {
  .info-item-title {
    min-width: 117px;
  }
}
