.left-menu {
  height: calc(100vh - 64px);
  border-right: 1px solid #e7e8ec;
  display: flex;
  flex-direction: column;
  padding-bottom: 31px;
  width: 280px;
  overflow: auto;
}

.drawer-paper {
  top: 64px;
  border: none;
}

.avatar {
  width: 56px;
  height: 56px;
  margin: 24px 0 8px 24px;
  border: 1px solid #ced0d9;
  cursor: pointer;
}

.user-icon-wrapper {
  margin: 24px 0 8px 24px;
  border-radius: 4px;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: #fff;
}

.user-profile {
  border-bottom: 1px solid #e7e8ec;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.username {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expand-more {
  margin-left: auto;
  width: 38px;
  height: 38px;
  border-radius: 0;
}

.expand-more:hover {
  background: #f5f6f8 !important;
}

.expand-more-icon {
  transition: 0.4s ease;
  transform: rotate(-180deg);
}

.rotate0 {
  transform: rotate(0);
}

.user-info {
  display: flex;
  padding: 0 8px 0 24px;
  margin-bottom: 23px;
  align-items: center;
}

.hidden-buttons,
.is-expanded {
  transition: all 0.5s ease;
  overflow: hidden;
}

.hidden-buttons {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.hidden-button,
.link-button,
.help-button {
  padding-left: 16px;
  margin: 0 8px;
  width: calc(100% - 16px);
  min-height: 48px;
  justify-content: flex-start;
}

.is-expanded {
  height: 0;
  margin-bottom: 0;
  opacity: 0;
}

.horizontal-line {
  width: 232px;
  min-height: 1px;
  margin: 0 auto;
  background: #e7e8ec;
  margin-bottom: 16px;
}

.link-buttons {
  margin-bottom: 16px;
}

.bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
}

.language {
  padding-top: 8px;
  cursor: pointer;
}

.relative {
  position: relative;
}

.popover {
  padding: 8px 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 1px;
}

.language-button {
  min-height: 0;
  padding: 13px 24px;
}

.link-button {
  position: relative;
}

.active-link {
  background: #f1f1f4;
}

.margin-top-12 {
  margin-top: 12px;
}

.start-icon {
  margin-right: 24px;
}

.save-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1023px) {
  .drawer-paper {
    top: 0;
  }

  .left-menu {
    height: 100%;
  }
}

@media screen and (min-width: 1023px) {
  .drawer-paper {
    transition: 0.5s ease transform !important;
  }
}

/* .active-link ::after {
  content: '';
  right: 32px;
  top: 21px;
  display: block;
  position: absolute;
  height: 5px;
  width: 5px;
  background: #ff615e;
  border-radius: 50%;
} */
